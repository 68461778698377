@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 99999;
  }
}
.filterPrimary {
  display: flex;
  flex-basis: 100%;
  padding: 40px 36px 0;
  @media (max-width: 1199px) {
    flex-wrap: wrap;
  }
  @media (max-width: 991px) {
    padding: 40px 0 0;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}
.ageForm {
  display: flex;
  flex-direction: column;
  margin-left: 6%;
}
.applyAge {
  /* margin: 50px auto 20px; */
  margin-left: 10%;
  max-width: 15%;
  min-height: 10px;
  display: block;
  border-radius: 10px;
  width: 50%;
  font-size: 16px;
  font-weight: 300;
}
.clearAge {
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  max-width: 15%;
  margin-left: 5%;
}
.clearAll {
  background-color: transparent;
  color: var(--matterColorDark);
  min-height: auto;
  font-size: 16px;
  font-weight: 500;
  width: auto;
  font-size: 14px;
  &:hover {
    color: var(--marketplaceColor);
    background-color: transparent;
    box-shadow: none;
  }
  @media (max-width: 991px) {
    margin: 12px 0 0 auto;
  }
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }
}
.searchByCounty {
  border: 1px solid var(--matterColorDark);
  /* background-color: rgb(131, 20, 20); */
  border-radius: 5px;
  /* width: 75%; */
}
.ageField {
}
.ageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 36px;
  @media (max-width: 991px) {
    padding: 0;
  }
}
.container {
  /* Layout */
  width: 100%;
  align-items: flex-start;
  flex-grow: 1;
  display: flex;
  padding: 50px 15px;
  /* flex-direction: column; */
  flex-wrap: wrap;
  background: #f2f1ef;
  margin-top: 40px;
  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (max-width: 767px) {
    padding: 15px;
    margin-top: 0px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;
  background: none;

  @media (max-width: 1199px) {
    padding: 0 !important;
  }

  @media (--viewportMedium) {
    display: flex;
    padding: 0 0 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 0 0 36px;
    flex-basis: 100%;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;
  @media (max-width: 767px) {
    padding: 0;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  z-index: 2;
  /* display: flex; */
  /* height: 100%; */
  flex-basis: 45%;
  margin: 25px 5px 0 0;
  max-width: 50%;
  overflow: hidden;
  /* box-shadow: -1px 0px 10px rgba(0, 0, 0, 0.3); */
  position: sticky;
  top: 142px;
  /* max-height: 70vh; */

  & > div:first-child {
    height: 100%;
  }
  @media (min-width: 768px) {
    display: block;
  }
  @media (max-width: 767px) {
    margin: 0;
    max-width: 100%;
    overflow: visible;
    box-shadow: none;
    position: absolute;
    top: 0;
    max-height: none;
    z-index: 11;
  }
}
.srchpageup {
  display: flex;
  flex-basis: 50%;
  max-width: 50%;
  @media (max-width: 767px) {
    max-width: 100%;
    flex-basis: unset;
  }
}
.sortBy {
  margin-right: 9px;
  @media (max-width: 767px) {
    display: none;
  }
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
  /* @media (max-width: 767px) {
    position: absolute;
    top: 0;
  } */
}

.map {
  width: 100vw;
  height: 78vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0; */

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    /* height: calc(100vh - var(--topbarHeightDesktop)); */
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
  @media (max-width: 575px) {
    /* height: 100%; */
    width: 100%;
  }
  & > div:first-child {
    border-radius: 20px;
  }
}
.resultsFound {
  box-shadow: 0px 0px 17px 4px #cecece;
  padding: 5px 15px;
  border-radius: 10px;
  @media (min-width: 768px) {
    margin: 0 20px;
  }
}
.resultsFound span {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
.searchResultSummary {
  display: flex;
  @media (max-width: 1199px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
  }
}
.selectedFilters {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.selectedFilter {
  margin: 0 2px;
  color: rgba(201, 59, 84, 1);
  text-decoration: underline;
  font-size: 14px;
  display: flex;
  align-items: center;
  /* &:hover {
    color: rgba(146, 27, 152, 1);
  } */
}
.mapcontent {
  /* @apply --marketplaceModalInMobileBaseStyles;

  @media (--viewportMedium) {
    flex-basis: 576px;
  } */
  height: 93%;
  width: 95%;
  margin: auto;
}
.innerfooter svg path {
  fill: #002435;
}
.fliterppup {
  display: flex;
  flex-basis: 100%;
  @media (max-width: 767px) {
  }
}
.serchinput {
  flex-basis: 30%;
  position: relative;
  @media (max-width: 1199px) {
    flex-basis: 50%;
    margin: 0 0 20px;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
  }
}
.srchSelect {
  flex-basis: 30%;
  & > div > div {
    width: 100%;
    min-width: 280px;
  }
  @media (min-width: 767px) {
    margin-bottom: 15px;
  }
}
.srchSelect button {
  width: 100%;
}

.serchinput input {
  background: #fff;
  border: 1px solid #d7d7d7;
  padding: 4px 15px 4px 38px;
  font-size: 14px;
}
.serchinput svg {
  position: absolute;
  top: 6px;
  left: 13px;
  /* height: 30px; */
  width: 18px;
  color: #bbb;
}
.backbtn {
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  padding: 1px 18px;
  border: 0;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 5px 18px;
  }
}
.backbtn svg {
  height: 22px;
  width: 15px;
  margin: 0 5px 0 0;
}
.btnact button {
  background: #1f8790;
}
.catact button {
  background: #113e53;
}
.agsact button {
  background: #bb354e;
}
.cpact button {
  background: #db7c2c;
}
.cstfilt {
  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    background: #767676 !important;
    padding: 10px 25px;
    justify-content: space-between;
    margin: 0 -15px 15px;
  }
}
.bcktldv {
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  justify-content: space-between;
}
